// Dependencies
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components, assets, actions, etc..
import LineSpinner from '../spinners/LineSpinner';
import NotificationItem from './NotificationItem';
import ItemFiller from './ItemFiller';

function ActivityFeed(props) {
  const { t } = useTranslation();
  const {
    givenName, familyName, notifications, length, icon,
  } = props;

  const renderNotifications = (items) => {
    const itemScope = length > 0 ? items.slice(0, length) : items;

    if (!itemScope.length) {
      return <ItemFiller title={t('components.activity.activityFeed.noNotificationsYet')} description={t('components.activity.activityFeed.pleaseAdd')} />;
    }
    return itemScope.map((item) => {
      let title = item.type;
      if (item.type === 'User') title = !givenName ? `${givenName} ${familyName} (You)` : 'You';

      return (
        <NotificationItem
          key={item._id}
          notification={item}
          title={title}
          icon={item.icon ? item.icon : icon}
        />
      );
    });
  };

  return (
    <div className="col-12 col-xl-5 d-none" id="recentActivity">
      <div className="card">
        <div className="card-header">
          <h4 className="card-header-title">{t('components.activity.activityFeed.recentActivity')}</h4>
          <Link to="/dashboard/notifications" className="btn btn-sm btn-primary" data-toggle="modal">
            {t('components.activity.activityFeed.viewAll')}
          </Link>
        </div>
        <div className="card-body">
          { notifications ? (
            <div className="list-group list-group-flush list-group-activity my-n3">
              {renderNotifications(notifications)}
            </div>
          ) : (
            <LineSpinner name={t('views.dashboard.savings.spinners.savings')} />
          )}
        </div>
      </div>
    </div>
  );
}

export default ActivityFeed;

ActivityFeed.propTypes = {
  givenName: PropTypes.string,
  familyName: PropTypes.string,
  icon: PropTypes.string,
  length: PropTypes.number.isRequired,
  notifications: PropTypes.arrayOf(PropTypes.object),
};

ActivityFeed.defaultProps = {
  icon: '',
  givenName: '',
  familyName: '',
  notifications: [],
};

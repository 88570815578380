// Dependencies
import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Components, actions, assets, etc..
import { setUser, signIn } from '../../actions/userActions';

function SignIn() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    loginWithRedirect,
    isAuthenticated,
    user,
    getAccessTokenSilently,
  } = useAuth0();

  return isAuthenticated ? (
    dispatch(setUser(user)) && dispatch(signIn(getAccessTokenSilently)) && (
      <Navigate to="/dashboard" />
    )
  ) : (
    <button
      type="button"
      onClick={() => loginWithRedirect()}
      className="btn btn-primary"
    >
      { t('components.auth0.signIn') }
    </button>
  );
}

export default SignIn;

/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSpendingTotal, fetchTransactions } from '../../actions/spending/transactionsActions';
import { fetchThreatsActive, fetchThreatsIdentified } from '../../actions/dashboard/widgetsActions';

import 'react-datepicker/dist/react-datepicker.css';
import { fetchSavings } from '../../actions/dashboard/savingsActions';
import { fetchSecurities } from '../../actions/dashboard/securityActions';
import Switch from '../common/inputs/baseSwitch';
import './styles.css';
import { SET_FILTERS } from '../../actions/types';
import { vendors } from '../../constants/clouds';
import BaseDropdown from '../common/inputs/baseDropdown';

const vendorsOptions = [vendors.AWS, vendors.Azure, vendors.GCP, vendors.OCI];

function DashboardFilters({ t, organizationId }) {
  const dispatch = useDispatch();
  const { filters: filtersState, vendors: vendorsState } = useSelector((state) => state);

  const onFiltersSubmit = async () => {
    const { inUse, filterSwitch, ...filters } = filtersState;
    const threatFiltersFormat = {
      filters: {
        start_date: filtersState.start,
        end_date: filtersState.end,
        type: filtersState.type,
        vendors: filters.vendors,
      },
    };

    await dispatch(fetchTransactions({ organizationId, limit: 'all', dataFilter: filters }));
    await dispatch(fetchThreatsIdentified({
      organizationId,
      ...threatFiltersFormat,
    }));
    await dispatch(fetchThreatsActive({
      organizationId,
      ...threatFiltersFormat,
    }));
    await dispatch(fetchSavings({ organizationId, dataFilter: filters }));
    await dispatch(fetchSecurities({ organizationId, dataFilter: filters }));
    await dispatch(fetchSpendingTotal({ organizationId, filters }));
  };

  const onVendorsChange = (selectedVendors) => {
    const endVendors = selectedVendors.map((vendor) => {
      const foundVendor = vendorsState.find((vendorInStore) => vendorInStore.name === vendor);

      if (foundVendor) {
        return foundVendor._id;
      }
      return {
        ...vendor,
      };
    });
    dispatch({ type: SET_FILTERS, payload: { vendors: endVendors } });
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-header-title my-2">{t('views.dashboard.filters.title')}</h4>
            <Switch
              isOn={filtersState.filterSwitch}
              handleToggle={() => dispatch({ type: SET_FILTERS, payload: { filterSwitch: !filtersState.filterSwitch } })}
            />

          </div>
          {
            filtersState.filterSwitch ? (
              <div className="card-body animated-fade">
                <Row className="g-1">
                  <Col xs="6" md="auto" className="filter-col-style my-2">

                    <h4 className="card-header-title mr-2">{t('views.dashboard.filters.startDateLabel')}</h4>
                    <DatePicker
                      className="def-date-picker form-control"
                      selected={filtersState.start}
                      onChange={(date) => dispatch({ type: SET_FILTERS, payload: { start: date } })}
                    />
                  </Col>
                  <Col xs="6" md="auto" className="filter-col-style my-2">
                    <h4 className="card-header-title mr-2">{t('views.dashboard.filters.endDateLabel')}</h4>
                    <DatePicker
                      className="def-date-picker form-control"
                      selected={filtersState.end}
                      onChange={(date) => dispatch({ type: SET_FILTERS, payload: { end: date } })}
                    />
                  </Col>
                  <Col xs="6" md="auto" className="filter-col-style my-2">
                    <BaseDropdown
                      optionsList={vendorsOptions}
                      onSelectionChange={(selectedVendors) => onVendorsChange(selectedVendors)}
                    />
                  </Col>
                  <Col md="auto">
                    <Form.Select
                      className="form-control my-2"
                      onChange={(evt) => dispatch({ type: SET_FILTERS, payload: { type: evt.target.value } })}
                    >
                      <option>{t('views.dashboard.filters.findingTypeLabel')}</option>
                      <option value="Security">{t('views.dashboard.security.title')}</option>
                      <option value="Saving">{t('components.organizations.organizationCard.savings')}</option>
                    </Form.Select>
                  </Col>
                  <Col md="" className="filter-col-style flex-grow-1 d-flex align-items-center mt-3 mt-sm-0 justify-content-center justify-content-sm-end">
                    <Link onClick={() => onFiltersSubmit()} className="btn btn-sm btn-primary">
                      {t('views.dashboard.filters.submitFilters')}
                    </Link>
                  </Col>
                </Row>
              </div>
            ) : null
          }
        </div>
      </div>
    </div>
  );
}

DashboardFilters.propTypes = {
  t: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
};

export default DashboardFilters;

import hypercube from '../../api/hypercube';
import { toggleModal } from '../modalActions';
import {
  DELETE_INTEGRATION,
  FEED_MODAL,
  FETCH_INTEGRATIONS,
  FETCH_INTEGRATIONS_EDGES,
  TOGGLE_ACTIVE_INTEGRATION,
  UPDATE_INTEGRATION,
} from '../types';

export const fetchIntegrations = (properties) => async (dispatch) => {
  let { limit, pageNumber, dataFilter } = properties;

  if (!limit) limit = 10;
  if (!pageNumber) pageNumber = 1;
  if (!dataFilter) dataFilter = {};

  const params = {
    limit,
    pageNumber,
    organizationId: properties.organizationId,
    dataFilter,
    sortBy: 'createdAt:desc',
  };

  const res = await hypercube.get('/integrations', { params });
  dispatch({ type: FETCH_INTEGRATIONS, payload: res.data });
};

export const connectIntegration = (
  {
    name, description, organizationId, ...credentials
  },
  vendor,
) => async (dispatch) => {
  try {
    const requestString = `/integrations?vendor=${vendor}&organizationId=${organizationId}`;
    await hypercube.post(requestString, {
      name,
      description,
      organizationId,
      credentials,
    });
    dispatch({ type: FEED_MODAL, payload: { type: 'success' } });
    dispatch(fetchIntegrations({ organizationId }));
  } catch (err) {
    dispatch({
      type: FEED_MODAL,
      payload: {
        type: 'fail', from: 'integration', vendor, err,
      },
    });
  }
};

export const deleteIntegration = (id, isActive) => async (dispatch) => {
  try {
    await hypercube.delete(`integrations/${id}`);
    dispatch({ type: DELETE_INTEGRATION, payload: { id, isActive } });
    dispatch(toggleModal({ type: 'success', from: 'disconnection request' }));
  } catch (err) {
    dispatch({
      type: FEED_MODAL,
      payload: { type: 'fail', from: 'integration removal', err },
    });
  }
};

export const toggleActiveIntegration = (id, isActive) => async (dispatch) => {
  try {
    await hypercube.patch(`integrations/${id}`, { isActive });
    dispatch({ type: TOGGLE_ACTIVE_INTEGRATION, payload: { id, isActive } });
    dispatch(toggleModal({ type: 'success', from: 'disconnection request' }));
  } catch (err) {
    dispatch({
      type: FEED_MODAL,
      payload: { type: 'fail', from: 'integration removal', err },
    });
  }
};

export const updateIntegration = (integration) => async (dispatch) => {
  try {
    await hypercube.patch(`integrations/${integration.id}`, {
      name: integration.name,
      description: integration.description,
    });
    dispatch({
      type: UPDATE_INTEGRATION,
      payload: integration,
    });
    dispatch({
      type: FEED_MODAL,
      payload: { type: 'success' },
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: FEED_MODAL,
      payload: { type: 'fail', from: 'integration removal', err },
    });
  }
};

export const fetchIntegrationsEdges = (properties) => async (dispatch) => {
  try {
    const res = await hypercube.get(`/integrations/edges?organizationId=${properties.organizationId}`);
    dispatch({ type: FETCH_INTEGRATIONS_EDGES, payload: res.data });
  } catch (err) {
    dispatch({
      type: FEED_MODAL,
      payload: { type: 'fail', from: 'integration edges', err },
    });
  }
};

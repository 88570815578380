import hypercube from '../../api/hypercube';
import {
  FETCH_ACCOUNTS_WIDGET,
  FETCH_THREATS_IDENTIFIED,
  FETCH_THREATS_ACTIVE,
} from '../types';

export const fetchAccounts = ({ organizationId }) => async (dispatch) => {
  const res = await hypercube.get('/users/integrations/count', {
    params: {
      ...(organizationId) ? { organizationId } : {},
    },
  });

  if (!res.data.length) return;

  const vendors = {};
  res.data.forEach((vendor) => {
    vendors[vendor._id] = vendor.total;
  });

  dispatch({ type: FETCH_ACCOUNTS_WIDGET, payload: vendors });
};

// /users/threats/created
export const fetchThreatsIdentified = ({ organizationId, filters }) => async (dispatch) => {
  const res = await hypercube.get('/users/threats/created', {
    params: {
      ...(organizationId) ? { organizationId } : {},
      ...(filters && { ...filters }),
    },
  });

  if (!res.data) return;

  const { totals: counters, resolvedCount, createdCount } = res.data;

  const totals = {
    resolved: (counters.length) ? counters[0].resolved : 0,
    created: (counters.length) ? counters[0].created : 0,
  };

  const payload = {
    totals,
    resolved: resolvedCount,
    created: createdCount,
  };

  dispatch({ type: FETCH_THREATS_IDENTIFIED, payload });
};

export const fetchThreatsActive = ({ vendor, organizationId, filters }) => async (dispatch) => {
  const res = await hypercube.get('/users/threats/active', {
    params: {
      ...vendor || {},
      ...(vendor) ? { vendor } : {},
      ...(organizationId) ? { organizationId } : {},
      ...(filters && { ...filters }),
    },
  });
  const { total, groupByPriority } = res.data;
  let payload;

  if (!total.length) {
    payload = { total, priorities: groupByPriority };
    dispatch({ type: FETCH_THREATS_ACTIVE, payload });
    return;
  }

  let findingsWithoutPriorities;
  const priorities = {};

  groupByPriority.forEach((priority) => {
    if (!priority._id) {
      findingsWithoutPriorities = priority.count;
      return;
    }
    priorities[priority._id] = priority.count;
  });

  payload = {
    total: (findingsWithoutPriorities)
      ? total[0].count - findingsWithoutPriorities : total[0].count,
    priorities,
  };

  dispatch({ type: FETCH_THREATS_ACTIVE, payload });
};

import { FETCH_SAVINGS, FETCH_SAVINGS_EDGES } from '../../actions/types';

const INITIAL_STATE = null;

export default function savingsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_SAVINGS:
      return {
        ...state,
        all: action.payload.result,
      };
    case FETCH_SAVINGS_EDGES:
      return {
        ...state,
        totals: {
          ...action.payload.totals,
        },
      };
    default:
      return state;
  }
}

import {
  FETCH_DEFAULT_ORGANIZATION,
  FETCH_ORGANIZATIONS,
  FETCH_ORGANIZATION_EDGES,
  FETCH_ORGANIZATION_MEMBERS,
  FETCH_ORGANIZATION_INVITATIONS,
  SET_ORGANIZATION_PERSONAL_FLAG,
  FETCH_ORGANIZATION_INSIGHT,
} from '../../actions/types';

const INITIAL_STATE = {
  all: {
    result: [],
    edges: { total: 0, active: 0, inactive: 0 },
  },
  currentOrganization: {},
};

export default function organizationsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_DEFAULT_ORGANIZATION:
      return {
        ...state,
        currentOrganization: action.payload,
      };
    case FETCH_ORGANIZATIONS:
      return {
        ...state,
        all: action.payload,
      };
    case FETCH_ORGANIZATION_MEMBERS:
      return {
        ...state,
        members: action.payload,
      };
    case FETCH_ORGANIZATION_INVITATIONS:
      return {
        ...state,
        invitations: action.payload,
      };
    case FETCH_ORGANIZATION_EDGES:
      return {
        ...state,
        edges: action.payload,
      };
    case FETCH_ORGANIZATION_INSIGHT:
      return {
        ...state,
        insights: action.payload,
      };
    case SET_ORGANIZATION_PERSONAL_FLAG:
      return {
        ...state,
        isPersonal: action.payload,
      };
    default:
      return state;
  }
}

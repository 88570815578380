// Dependencies
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  useTable, useGlobalFilter, useSortBy, usePagination,
} from 'react-table';
import ReactPaginate from 'react-paginate';

// Components, actions, assets, etc..
import GlobalFilter from './GlobalFilter';
import './table.css';
import { updateLocation } from '../../../actions/navigationActions';
import getPaywallState from '../../../helpers/getPaywallState';
import billingRedirect from '../../../actions/dashboard/billingActions';

function TableWrapper({
  columns,
  data,
  total,
  getItems,
  model,
  paginate,
  dataFilter,
  searchInstructions,
  showFilter,
  searchFilterObject,
}) {
  const { t } = useTranslation();
  const currentOrganization = useSelector((state) => state.organizations.currentOrganization);
  const findingId = useSelector((state) => state?.resource?.findingId);
  const pathname = `/dashboard/${model}${model === 'resources' ? `/${findingId}` : ''}`;

  const { titleFilter, setTitleFilter } = searchFilterObject;
  const tableColumns = React.useMemo(() => columns, [columns]);
  const tableData = React.useMemo(() => data, [data]);
  const [filter, setFilter] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const dispatch = useDispatch();
  const responseData = useSelector((state) => state[model]);
  data = responseData?.result;

  const { activePay, payLimit, payPageName } = getPaywallState(model, currentOrganization);

  useEffect(() => {
    setPageNumber(1);
  }, [total]);

  const tableInstance = useTable({
    columns: tableColumns,
    data: tableData,
  }, useGlobalFilter, useSortBy, usePagination);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
  } = tableInstance;

  const pageChangeHandler = (currentPage) => {
    setPageNumber(currentPage.selected + 1);
    dataFilter = { ...dataFilter, filter };
    dispatch(getItems({
      dataFilter,
      limit: 10,
      pageNumber: currentPage.selected + 1,
      ...((model === 'resources' && findingId) && { findingId }),
      ...(model !== 'resources' && { organizationId: currentOrganization._id }),
    }));
    dispatch(updateLocation({ pathname, data: { dataFilter } }));
  };
  const canNextPage = () => (pageNumber < (Math.ceil(total / 10)));
  const canPreviousPage = () => (pageNumber > 1);
  const updateDataHandler = (toFilter) => {
    if (toFilter || toFilter === '') {
      dataFilter = { ...dataFilter, filter: toFilter };
      setFilter(toFilter);
      dispatch(getItems({
        dataFilter,
        limit: 10,
        pageNumber: 1,
        ...((model === 'resources' && findingId) && { findingId }),
        ...(model !== 'resources' && { organizationId: currentOrganization._id }),
      }));
      dispatch(updateLocation({ pathname, data: { dataFilter, titleFilter } }));
    }
  };

  return (
    <>
      {
        showFilter
          ? (
            <GlobalFilter
              updateDataHandler={updateDataHandler}
              searchInstructions={searchInstructions}
              titleFilter={titleFilter}
              setTitleFilter={setTitleFilter}
            />
          )
          : null
      }
      <div className="table-responsive mb-0" data-toggle="lists">
        <table
          {...getTableProps()}
          className="table table-sm table-nowrap card-table"
        >
          <thead className="text-center">
            {
            headerGroups.map((headerGroup) => (
              <tr className="text-center" {...headerGroup.getHeaderGroupProps()}>
                {
                  headerGroup.headers.map((column) => (
                    <th className="text-center" {...column.getHeaderProps()}>
                      <span
                        className="text-muted cursor-pointer sort"
                        data-sort="name-column"
                        {...column.getSortByToggleProps()}
                      >
                        {column.render('Header')}
                      </span>
                    </th>
                  ))
                  }
              </tr>
            ))
            }
          </thead>
          <tbody {...getTableBodyProps()}>
            { page.map((row, index) => {
              prepareRow(row);
              // TODO: Move this condition to not be executed every row
              const isWithinLimit = index < payLimit;
              return (
                <>
                  { (activePay && index === payLimit) && (
                    <tr>
                      <td colSpan={columns.length} className="text-center p-3">
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-header-title">
                              <a
                                href="#"
                                onClick={
                                  // eslint-disable-next-line max-len
                                  () => dispatch(billingRedirect({ orgId: currentOrganization?._id }))
                                }
                              >
                                Upgrade your plan for more
                                {' '}
                                {payPageName}
                              </a>
                            </h4>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                  <tr
                    {...row.getRowProps()}
                    style={{
                      filter: isWithinLimit ? 'none' : 'blur(3px)',
                      pointerEvents: isWithinLimit ? 'auto' : 'none',
                      userSelect: isWithinLimit ? 'auto' : 'none',
                    }}
                  >
                    {row.cells.map((cell) => (
                      <td
                        className="text-center p-3"
                        {...cell.getCellProps()}
                        style={{ pointerEvents: isWithinLimit ? 'auto' : 'none' }}
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
      {
        paginate && !activePay && total >= 10 && payLimit >= 10 ? (
          <ReactPaginate
            forcePage={pageNumber - 1}
            containerClassName="pagination d-flex justify-content-center mt-2 pagination-container"
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            previousLabel={t('components.common.tableWrapper.previous')}
            previousClassName={canPreviousPage() ? 'page-item' : 'page-item disabled'}
            previousLinkClassName="page-link d-inline-flex cursor-pointer"
            nextLabel={t('components.common.tableWrapper.next')}
            nextClassName={canNextPage() ? 'page-item' : 'page-item disabled'}
            nextLinkClassName="page-link d-inline-flex cursor-pointer"
            breakLabel="..."
            pageClassName="page-item"
            pageLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageCount={Math.ceil(total / 10)}
            activeClassName="active"
            onPageChange={pageChangeHandler}
          />
        ) : null
      }
    </>
  );
}

TableWrapper.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  total: PropTypes.number,
  paginate: PropTypes.bool,
  getItems: PropTypes.func.isRequired,
  searchInstructions: PropTypes.string.isRequired,
  searchFilterObject: PropTypes.shape({
    titleFilter: PropTypes.string,
    setTitleFilter: PropTypes.func,
  }),
  showFilter: PropTypes.bool,
  dataFilter: PropTypes.shape({}),
  model: PropTypes.string.isRequired,
};

TableWrapper.defaultProps = {
  columns: [],
  data: [],
  total: 0,
  paginate: true,
  showFilter: true,
  dataFilter: {},
  searchFilterObject: {
    titleFilter: '',
    setTitleFilter: () => {},
  },
};

export default TableWrapper;

import hypercube from '../../api/hypercube';
import { FETCH_TRANSACTIONS, FETCH_TOTAL_SPENDING } from '../types';

export const fetchSpendingTotal = (properties) => async (dispatch) => {
  const requestString = `/transactions/spendings/total?currency=USD&organizationId=${properties.organizationId}`;
  const filters = properties?.filters || null;
  const res = await hypercube.get(requestString, {
    params: {
      ...(filters && {
        filters,
      }),
    },
  });

  dispatch({ type: FETCH_TOTAL_SPENDING, payload: res.data });
};

export const fetchTransactions = (properties) => async (dispatch) => {
  let {
    limit, pageNumber, dataFilter,
  } = properties;

  const { organizationId } = properties;

  if (!limit) limit = 10;
  if (!pageNumber) pageNumber = 1;
  if (!dataFilter) dataFilter = {};

  const res = await hypercube.get(
    '/transactions?sortBy=invoiceTimestamp:desc',
    {
      params: {
        dataFilter,
        limit,
        pageNumber,
        organizationId,
      },
    },
  );
  dispatch({ type: FETCH_TRANSACTIONS, payload: res.data });
};

// Dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// Components, actions, assets, etc..

function ItemFiller(props) {
  const { title, description, to } = props;

  return (
    <Link to={to} className="text-dark">
      <div className="list-group-item cursor-pointer">
        <div className="row">
          <div className="col-auto">
            <div className="avatar avatar-sm avatar-online">
              <img
                className="avatar-img rounded-circle"
                src="https://cdn.gethypercube.com/logo512.png"
                alt="Notification"
              />
            </div>
          </div>
          <div className="col ml-n2">
            <h5 className="mb-1">{title}</h5>
            <p className="small text-gray-700 mb-0">{description}</p>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default ItemFiller;

ItemFiller.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  to: PropTypes.string,
};

ItemFiller.defaultProps = {
  to: '/dashboard/integrations',
};

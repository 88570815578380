// Dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';
// Components, actions, assets, etc..
import SignIn from '../../components/auth0/SignIn';
import SignUp from '../../components/auth0/SignUp';
import logo from '../../images/logos/obok-logo.svg';
import './Landing.css';

function Landing() {
  const { t } = useTranslation();

  return (
    <div className="container-fluid">
      <div className="row align-items-center justify-content-center">
        <div className="d-flex flex-column align-items-center justify-content-center col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-5">
          <div className="p-5">
            <svg width="270px" height="60px">
              <image href={logo} className="img-fluid" alt="Obok logo" width="100%" />
            </svg>
          </div>
          <p data-testid="visible" className="text-muted text-center mb-3">
            { t('views.dashboard.landing.welcomeBack') }
          </p>
          <div className="row align-items-center justify-content-center mb-5">
            <SignIn />
          </div>
          <p className="text-muted text-center mb-3">
            <span>{ t('views.dashboard.landing.dontHave') }</span>
            <SignUp />
          </p>
        </div>
        <div className="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
          <div id="bg-landing" className="vh-100 mr-n3" />
        </div>
      </div>
    </div>
  );
}

export default Landing;

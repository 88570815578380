import hypercube from '../../api/hypercube';
import { FEED_MODAL, FETCH_SAVINGS, FETCH_SAVINGS_EDGES } from '../types';

export const fetchSavings = (properties) => async (dispatch) => {
  let { limit, pageNumber, dataFilter } = properties;

  if (!limit) limit = 10;
  if (!pageNumber) pageNumber = 1;
  if (!dataFilter) dataFilter = {};

  const params = {
    limit,
    pageNumber,
    organizationId: properties.organizationId,
    dataFilter,
    sortBy: 'updatedAt:desc',
    type: 'Saving',
  };

  const res = await hypercube.get('/recommendations', { params });
  dispatch({ type: FETCH_SAVINGS, payload: res.data });
  dispatch({ type: FETCH_SAVINGS_EDGES, payload: res.data });
};

export const deleteSaving = (id) => async (dispatch) => {
  try {
    const res = await hypercube.delete(`/recommendations/${id}`);
    return res.status === 200;
  } catch (err) {
    dispatch({
      type: FEED_MODAL,
      payload: { type: 'fail', from: 'saving deletion', err },
    });
    return false;
  }
};

export const archivedSaving = (properties) => async (dispatch) => {
  try {
    const { id, archived } = properties;
    const res = await hypercube.patch(`/recommendations/${id}`, { archived: !archived });
    return res.status === 201;
  } catch (err) {
    dispatch({
      type: FEED_MODAL,
      payload: { type: 'fail', from: 'archiving saving', err },
    });
    return false;
  }
};

export const fetchSavingsEdges = (properties) => async (dispatch) => {
  try {
    const { type, organizationId } = properties;
    const requestString = `/recommendations/edges?type=${type}&organizationId=${organizationId}`;
    const res = await hypercube.get(requestString);

    dispatch({ type: FETCH_SAVINGS_EDGES, payload: res.data });
  } catch (err) {
    dispatch({
      type: FEED_MODAL,
      payload: { type: 'fail', from: 'saving edges', err },
    });
  }
};

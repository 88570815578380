/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
/* eslint react/prop-types: 0 */
import 'chart.js/auto';
import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';

import 'chartjs-plugin-datalabels';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchThreatsActive } from '../../actions/dashboard/widgetsActions';
import { vendors } from '../../constants/clouds';

function ActiveThreats(props) {
  const [currentTab, setCurrentTab] = useState('All');
  const dispatch = useDispatch();
  const currentOrg = useSelector((state) => state?.organizations?.currentOrganization);
  const { t } = useTranslation();

  useEffect(() => {
    if (currentTab !== 'All') {
      dispatch(fetchThreatsActive({ vendor: vendors[currentTab], organizationId: currentOrg._id }));
    } else {
      dispatch(fetchThreatsActive({ organizationId: currentOrg._id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const { priorities } = props;

  const totalThreats = Object.values(priorities).reduce((accumulator, currentVal) => accumulator + currentVal, 0);

  const data = {
    labels: Object.keys(priorities),
    datasets: [
      {
        label: 'Findings',
        data: Object.values(priorities),
        backgroundColor: [
          '#7c69ef',
          '#14192d',
          '#c3c5ed',
        ],
        borderColor: [
          '#7c69ef',
          '#14192d',
          '#c3c5ed',
        ],
      },
    ],
  };

  const options = {
    borderWidth: 1,
    cutout: '80%',

  };

  const orderedData = {
    datasets: data.datasets,
    labels: ['LOW', 'MEDIUM', 'HIGH'],
  };

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title">
          Active findings
        </h4>
        <ul className="nav nav-tabs nav-tabs-sm card-header-tabs">
          {/* <li className="nav-item" data-toggle="chart" data-target="#trafficChart" data-trigger="click" data-action="toggle" data-dataset="0">
            <a href="#!" className={`nav-link ${currentTab === 'All' && 'active'}`} data-bs-toggle="tab" onClick={() => setCurrentTab('All')}>
              All
            </a>
          </li> */}

          {
            // hypercubeVendors.map((vendor) => (
            //   <li className="nav-item" data-toggle="chart" data-target="#trafficChart" data-trigger="click" data-action="toggle" data-dataset="1">
            //     <a href="#!" className={`nav-link ${currentTab === vendor && 'active'}`} data-bs-toggle="tab" onClick={() => setCurrentTab(vendor)}>
            //       {vendor}
            //     </a>
            //   </li>
            // ))
          }

        </ul>
      </div>
      <div className="card-body Doughnut min-height-dashboard-charts">
        <div className="CenterWrapper">
          <h1>{totalThreats}</h1>
        </div>
        <div className="CenterWrapperLabel">
          <h3>{t('views.dashboard.home.findings')}</h3>

        </div>
        {
          (Object.keys(priorities).length)
            ? (
              <Doughnut
                data={orderedData}
                options={options}
                plugins={{
                  legend: {
                    display: false,
                  },
                }}
              />
            )
            : (
              <div className="container-fluid py-5 my-3 px-0 mx-0 row rounded">
                <div className="col-md-12 text-center text-md-left" data-aos="fade-up">
                  <p className="font-size-lg text-center text-muted">
                    {t('components.fillers.tableResultFiller.noResults')}
                  </p>
                </div>
              </div>
            )
        }
      </div>
    </div>
  );
}

export default ActiveThreats;

import React, { useState } from 'react';
import {
  Dropdown, DropdownButton, ButtonGroup,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

function BaseDropdown({ optionsList, onSelectionChange }) {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const toggleOption = (option) => {
    setSelectedOptions((prevSelected) => {
      const updatedOptions = prevSelected.includes(option)
        ? prevSelected.filter((item) => item !== option)
        : [...prevSelected, option];
      onSelectionChange(updatedOptions);
      return updatedOptions;
    });
  };

  const isSelected = (option) => selectedOptions.includes(option);

  return (
    <DropdownButton
      as={ButtonGroup}
      id="dropdown-basic-button"
      title="Select Vendors"
      variant="primary"
    >
      {optionsList.map((vendor) => (
        <Dropdown.Item
          key={vendor}
          onClick={() => toggleOption(vendor)}
          active={isSelected(vendor)}
        >
          {isSelected(vendor) ? '☑️ ' : '☐ '}
          {' '}
          {vendor}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
}

export default BaseDropdown;

BaseDropdown.propTypes = {
  optionsList: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelectionChange: PropTypes.func.isRequired,
};

import {
  SET_USER, SIGN_IN, SIGN_OUT, RELOAD_USER,
} from '../actions/types';

const INITIAL_STATE = {
  user: null,
  token: null,
};

export default function authReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.payload };
    case SIGN_IN:
      return {
        ...state,
        user: Object.assign(state.user, action.payload.user),
        token: action.payload.token,
      };
    case RELOAD_USER:
      return { ...state, user: Object.assign(state.user, action.payload.user) };
    case SIGN_OUT:
      return { ...state, user: null };
    default:
      return state;
  }
}

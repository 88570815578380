import {
  DELETE_INTEGRATION,
  FETCH_INTEGRATIONS,
  FETCH_INTEGRATIONS_EDGES,
  TOGGLE_ACTIVE_INTEGRATION,
  UPDATE_INTEGRATION,
} from '../../actions/types';

const INITIAL_STATE = null;

// TODO: cache active inactive records when switching between tabs

export default function integrationsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_INTEGRATIONS:
      return {
        ...state,
        all: action.payload,
      };
    case FETCH_INTEGRATIONS_EDGES:
      return {
        ...state,
        edges: action.payload,
      };
    case UPDATE_INTEGRATION:
      return {
        ...state,
        all: {
          ...state.all,
          result: state.all.result.map((itg) => {
            if (itg._id === action.payload.id) {
              return { ...itg, ...action.payload };
            }
            return itg;
          }),
        },
      };
    case DELETE_INTEGRATION:
      return {
        ...state,
        all: {
          ...state.all,
          result: state.all.result.filter((itg) => itg._id !== action.payload.id),
          totals: {
            all: state.all.totals.all - 1,
            active: action.payload.isActive
              ? state.all.totals.active - 1
              : state.all.totals.active,
            inactive: !action.payload.isActive
              ? state.all.totals.inactive - 1
              : state.all.totals.inactive,
          },
        },
      };
    case TOGGLE_ACTIVE_INTEGRATION:
      return {
        ...state,
        all: {
          ...state.all,
          result: state.all.result.filter((itg) => itg._id !== action.payload.id),
          totals: {
            all: state.all.totals.all,
            active: action.payload.isActive
              ? state.all.totals.active + 1
              : state.all.totals.active - 1,
            inactive: !action.payload.isActive
              ? state.all.totals.inactive + 1
              : state.all.totals.inactive - 1,
          },
        },
      };
    default:
      return state;
  }
}
